<script setup lang="ts">
import type { GraphRun } from '@respell/database';
import CreateCampaignModal from '~/components/modals/CreateCampaignModal.vue';

const modal = useModal();
const agentStore = useAgentsStore();

const { campaigns } = storeToRefs(agentStore);

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'completed',
    label: 'Tasks performed',
  },
  {
    key: 'control',
    label: 'Pause / Resume',
  },
  // {
  //   key: 'actions',
  //   label: 'Actions',
  // },
];

const rows = computed(() => {
  return campaigns.value?.map((run: GraphRun) => ({
    id: run.id,
    name: run.name,
    status: run.state,
    completed:
      run._count?.steps ??
      run.steps?.filter(
        (step: StepRun) =>
          [
            'update_crm_companies',
            'add_person_to_hubspot',
            'send_email',
          ].includes(step.slug) && step.state === 'success',
      ).length,
    control: run.state === 'inProgress',
  }));
});

async function selectRun(run: GraphRun) {
  await navigateTo(`/agent/sdr/analytics/${run.id}`);
}

async function handleCampaignModal() {
  modal.open(CreateCampaignModal);
}
</script>
<template>
  <div
    class="border-container mt-m p-m pb-l bg-white w-full flex flex-col rounded-2xl gap-4 items-start"
  >
    <!-- HEADER -->
    <span class="flex justify-start w-full">
      <p class="title">
        {{
          agentStore.configState.companyName
            ? `${agentStore.configState.companyName}'s`
            : 'Your'
        }}
        Campaigns
      </p>
      <UButton
        label="Create new"
        icon="i-ph-plus"
        class="ml-auto"
        @click="handleCampaignModal"
      />
    </span>
    <!-- GROUP TABLE -->
    <UTable
      :rows="rows"
      :columns="columns"
      :empty-state="null"
      class="w-full"
      style="scrollbar-width: auto"
      :ui="{
        wrapper: 'border-0',
        base: 'border-separate border-spacing-y-2 divide-y-0',
        divide: 'divide-y-0',
        th: {
          font: 'font-normal',
          padding: 'py-0',
        },
        td: {
          base: 'border-y first:border-l first:rounded-l-2xl last:border-r last:rounded-r-2xl',
        },
      }"
      @select="selectRun"
    >
      <template #name-data="{ row }">
        <p class="body font-bold truncate">{{ row.name }}</p>
      </template>
      <template #status-data="{ row }">
        <span
          v-if="row.status === 'inProgress'"
          class="flex gap-1 justify-start"
        >
          <UIcon
            name="i-ph-arrow-clockwise"
            class="text-xl animate-spin text-primary-500"
          />
          <p class="body text-primary-500">Running...</p>
        </span>
        <UBadge
          v-else
          :color="row.status === 'success' ? 'green' : 'primary'"
          :label="row.status"
          :variant="row.status === 'success' ? 'solid' : 'subtle'"
          :ui="{ rounded: 'rounded-2xl', font: 'capitalize' }"
        />
      </template>
      <template #control-data="{ row }">
        <UToggle v-model="row.control" size="xl" disabled />
      </template>
    </UTable>
    <!-- EMPTY STATE -->
    <div
      v-if="!rows?.length"
      class="flex flex-col w-full py-6 gap-2 items-center"
    >
      <UIcon name="i-ph-smiley-melting" class="text-gray-400 text-3xl" />
      <p class="subtitle">You haven't created any campaigns yet</p>
      <p class="body dimmed">Create one to get started</p>
      <UButton
        label="Create new"
        icon="i-ph-plus"
        color="white"
        @click="handleCampaignModal"
      />
    </div>
  </div>
</template>
